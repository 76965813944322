
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import { VueCookieNext } from "vue-cookie-next";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { apiEndpoint } from "@/mixin/apiEndpoint";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "Add-Field-Visit",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {
        visitor_type: "",
        visiting_officer: "",
        role_id: "",
        visitor_entity_id: "",
        visit_type: "",
      },
      visitorType: [],
      employeeList: [],
      instituteList: [],
      visited: {
        entity: "",
        institute: "",
        institute_name: "",
        institute_address: "",
        insitute_district: "",
        visit_date_from: "",
        visit_date_to: "",
        report_submission_date: "",
      },
      instituteDetails: {} as any,
      disabled: 1,
      role_name: "",
      entityList: [],
      visitor_entity_name: "",
      visitor_entity_id: "",
      showInstituteInput: false,
      loading: false,
      load: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getVisitorTypes();
    //await this.getEntity();
    await this.getEntityList();
  },
  methods: {
    async formSubmit() {
      //form submit
      let data = new FormData();
      for (var key in this.batch) {
        data.set(key, this.batch[key]);
      }
      for (var key in this.visited) {
        data.set(key, this.visited[key]);
      }
      let user_id = VueCookieNext.getCookie('_seip_user');
      user_id = user_id.id;
      data.set('user_id', user_id);
      this.loading = true;
      ApiService.post("fieldvisit/save", data)
        .then((response) => {
          this.loading = false;
          if (response?.data?.status == "success") {
            Swal.fire({
              title: "Success",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.batch.visitor_type = "",
                this.batch.visiting_officer = "",
                this.batch.role_id = "",
                this.batch.visit_type = "",
                this.batch.visitor_entity_id = ""
              this.visited.entity = "",
                this.visited.institute = "",
                this.visited.institute_name = "",
                this.visited.institute_address = "",
                this.visited.insitute_district = "",
                this.visited.visit_date_from = "",
                this.visited.visit_date_to = "",
                this.visited.report_submission_date = ""
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getVisitorTypes() {
      this.load = true;
      let entity_type_id = VueCookieNext.getCookie("_seip_entity_type");
      await ApiService.get("fieldvisit/type?entity_type_id=" + entity_type_id)
        .then((response) => {
          console.log(response);
          this.visitorType = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntity(entity_id) {
      this.load = true;
      //let entity_id = VueCookieNext.getCookie("_entity_id");
      this.batch.visitor_entity_id = entity_id;
      ApiService.get("entity/show/" + entity_id)
        .then((response) => {
          this.visitor_entity_name = response.data.data.entity_short_name;
          console.log(response);
          this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getRole() {
      this.load = true;
      await ApiService.get(
        "fieldvisit/role?employee_id=" + this.batch.visiting_officer
      )
        .then((response) => {
          this.role_name = response.data.data.entitytyperole.role_name;
          if (response.data.data.employee.designation) {
            this.role_name = response.data.data.employee.designation;
          }
          this.batch.role_id = response.data.data.entity_type_role_id;
          this.getEntity(response.data.data.entity_id);
          this.load = false;
          //console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.load = true;
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API + "?entity_id=" + this.visited.entity
      )
        .then((response) => {
          this.instituteList = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInsituteDetails() {
      if (this.visited.institute == '-1') {
        this.disabled = 0;
        this.showInstituteInput = true;
      } else {
        this.visited.institute_name = "",
          this.visited.institute_address = "",
          this.disabled = 1;
        this.showInstituteInput = false;
        this.load = true;
        await ApiService.get("institute/show/" + this.visited.institute)
          .then((response) => {
            this.instituteDetails = response.data.data;
            this.visited.institute_address = this.instituteDetails.address;
            this.visited.insitute_district = this.instituteDetails.district;
            //console.log(response);
            this.load = false;
          })
          .catch((response) => {
            console.log(response);
          });
      }

    },
    async getEntityList() {
      this.load = true;
      let entity_type = VueCookieNext.getCookie("_seip_entity_type");
      let entity_id = "";
      if (entity_type != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("fieldvisit-report/entity?entity_id=" + entity_id)
        .then((response) => {
          this.entityList = response.data.data;
          this.load = false;
          //console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getEmployee() {
      let entity_type_id = VueCookieNext.getCookie("_seip_entity_type");
      let entity_id = VueCookieNext.getCookie("_entity_id");
      this.load = true;
      await ApiService.get(
        "fieldvisit/employeeList?entity_type_id=" +
        entity_type_id +
        "&entity_id=" +
        entity_id +
        "&visitor_type_id=" +
        this.batch.visitor_type
      )
        .then((response) => {
          this.employeeList = response.data.data;
          this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },
  },
  setup() { },
});
